@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

body {
  font-family: "Open Sans", sans-serif;
}

.main {
  display: grid;
  grid-template-columns: minmax(250px, 25%) auto;
  grid-template-rows: 100%;
  padding-top: 5px;
  height: calc(100vh - 75px);
}

.main-toc {
  padding-top: 5px;
  height: calc(100vh - 75px);
}

.main-toc > .display {
  padding: 30px;
  text-align: center;
}

.main-toc > .display p {
  font-size: 1rem;
  text-align: justify;
  padding: 5px;
}


.display {
  padding: 15px 15px 10px 20px;
}

select {
  width: 100%;
  padding: 10px;
  font-size: 18px;
}

h4 {
  font-weight: bold;
  margin-bottom: 5px;
}

.pointer {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.pointer .fas {
  height: 1rem;
  width: 1rem;
  margin: 0;
}

.mt-2 {
  margin-top: 2em;
}

.tooltip {
  background-color: #add6e4 !important;
  border: 1px solid #666 !important;
}
