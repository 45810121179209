.levers__btn {
  cursor: pointer;
  user-select: none;
  border: 1px solid #373737;
  text-align: center;
  width: 3ch;
  height: 3ch;
  text-align: center;
  line-height: 3ch;
  margin: 0 5px 0 0;
  font-size: 12px;
  font-weight: normal;
}

.levers__selected {
  background-color: #add6e4;
}

.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #607880;
  color: #fff;
}
.ripple:active {
  background-color: #c2e2eb;
  background-size: 100%;
  transition: background 0s;
}
