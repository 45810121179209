@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  height: 100vh;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #373737;
  font-size: 0.85em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal {
  position: fixed;
  z-index: 500;
  background-color: rgb(243, 243, 243);
  width: 30%;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px rgb(49, 49, 49);
  padding: 3em;
  left: 50%;
  top: auto;
  font-size: 14px;
  box-sizing: border-box;
  /*transition: all 0.1s ease-in-out;*/
}

.modal-lever {
  position: fixed;
  z-index: 500;
  background-color: rgb(243, 243, 243);
  width: 50%;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px rgb(49, 49, 49);
  padding: 20px;
  left: 30%;
  top: 10%;
  font-size: 14px;
  box-sizing: border-box;
  /*overflow-y: auto;
  max-height: calc(100vh - 210px);*/
  /*transition: all 0.1s ease-in-out;*/
}

/* Modal Header */
.modal-header {
  padding: 2px 16px;
  border-bottom: 2px solid #add6e4;
}

.modal-header h2 {
  margin: 0;
}

/* Modal Footer */
.modal-footer {
  padding: 2px 16px;
  background-color: #add6e4;
  color: white;
}

/* Modal Content */
.modal-content {
  /*height: auto !important;*/
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 210px);
  padding: 10px 16px 5px 5px;
  white-space: pre-wrap;
}

.calculate-new-btn {
  width: 25%;
  padding: 10px;
  margin: auto;
  border-radius: 3px;
  font-size: 14px;
  color: #333;
  border: 1px solid #ccc;
  background-color: #add6e4;
  cursor: pointer;
}

.btn-div {
  display: flex;
  align-items: center;
}

.calculate-new-btn:hover {
  background-color: #607880;
  color: white;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  font-size: 16px;
  margin-bottom: 5px;
}
.mb-4 {
  font-size: 16px;
  margin-bottom: 15px;
}
.sc-desc {
  font-size: 14px;
  margin-bottom: 10px;
}
.lead {
  font-size: 18px;
}
.lead p {
  margin: 10px 15px 20px 15px;
}

.ambition-level {
  font-size: 16px;
}
.ambition-level p {
  margin: 5px 10px 15px 10px;
}

ul {
  padding-left: 20px;
}

.Backdrop_Backdrop__2rdo3 {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.pathway {
  margin-bottom: 15px;
  width: auto;
  background: #add6e4;
  border: 1px solid #ababab;
  box-shadow: 0 0 10px #c6c5c5;
  padding: 15px;
}

.pathway .lead {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #607880;
}

.pathway-selector {
  width: 75%;
}

.pathway .pathway-control {
  display: flex;
  justify-content: center;
}

.pathway-control .calculate-btn {
  width: 25%;
  margin-left: 0.2rem;
  border-radius: 3px;
  font-size: 14px;
  color: #333;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
}

.pathway-control .info-button {
  margin-left: 20px;
  margin-top: 5px;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: white;
  color: #333;
  font-weight: 600;
}
.pathway-control .info-button:hover {
  background-color: #607880;
  color: white;
}
.pathway-control .calculate-btn:hover {
  background-color: #607880;
  color: white;
}

.levers__btn {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid #373737;
  text-align: center;
  width: 3ch;
  height: 3ch;
  text-align: center;
  line-height: 3ch;
  margin: 0 5px 0 0;
  font-size: 12px;
  font-weight: normal;
}

.levers__selected {
  background-color: #add6e4;
}

.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #607880;
  color: #fff;
}
.ripple:active {
  background-color: #c2e2eb;
  background-size: 100%;
  transition: background 0s;
}

.leverSelected {
  background: #999898;
  color: #f2f2f2;
  font-weight: bold;
}

.lever {
  padding: 3px 2px 3px 2px;
}

.all-selectors {
  min-width: 120px;
}

.pl-2 {
  padding-left: 1rem;
}

.all-selectors > * {
  display: inline-block;
}

.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}

.hide {
  display: none;
}

.mr {
  margin-right: 5px;
}

.headline {
  background-color: #e1dfdf;
  border-bottom: 1px solid rgb(128, 127, 127);
  font-weight: bold;
  font-size: 14px;
}

.group_1 {
  background-color: rgb(247, 246, 246);
  border-bottom: 1px solid rgb(128, 127, 127);
  font-weight: bold;
  font-size: 13px;
}

.group_2 {
  font-weight: bold;
  border-bottom: 1px solid rgb(179, 178, 178);
  padding-left: 10px;
}

.title:last-child {
  border-bottom: 1px solid rgb(128, 127, 127);
}

.loader {
  width: 100%;
  display: flex;
  margin-top: 4 0px;
  justify-content: space-around;
  text-align: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 50px;
  height: 50px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 44px;
  height: 44px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid rgb(162, 162, 162);
  border-color: rgb(162, 162, 162) transparent rgb(162, 162, 162) transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}
@-webkit-keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.levers {
  padding: 15px 0 10px 15px;
}

.all-levers {
  overflow-y: auto;
  height: calc(100%);
}

.toc-bottom {
  margin: 1px 5px 0 0 ;
  
}


.levers-wrapper {
  height: calc(100% - 90px); /*height: calc(100% - 123px); */
  border: 1px solid #999;
}

/* width */
.all-levers::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.all-levers::-webkit-scrollbar-track {
  background: rgb(247, 246, 246);
}

/* Handle */
.all-levers::-webkit-scrollbar-thumb {
  background: #ababab;
}

/* Handle on hover */
.all-levers::-webkit-scrollbar-thumb:hover {
  background: #bababa;
}

body {
  font-family: "Open Sans", sans-serif;
}

.main {
  display: grid;
  grid-template-columns: minmax(250px, 25%) auto;
  grid-template-rows: 100%;
  padding-top: 5px;
  height: calc(100vh - 75px);
}

.main-toc {
  padding-top: 5px;
  height: calc(100vh - 75px);
}

.main-toc > .display {
  padding: 30px;
  text-align: center;
}

.main-toc > .display p {
  font-size: 1rem;
  text-align: justify;
  padding: 5px;
}


.display {
  padding: 15px 15px 10px 20px;
}

select {
  width: 100%;
  padding: 10px;
  font-size: 18px;
}

h4 {
  font-weight: bold;
  margin-bottom: 5px;
}

.pointer {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.pointer .fas {
  height: 1rem;
  width: 1rem;
  margin: 0;
}

.mt-2 {
  margin-top: 2em;
}

.tooltip {
  background-color: #add6e4 !important;
  border: 1px solid #666 !important;
}

.map-control {
  background: #add6e4;
  border: 1px solid #ababab;
  padding: 5px;
  box-shadow: 0 0 10px #c6c5c5;
}

.map-control > div {
  align-items: center;
  padding: 5px;
}

.map-control-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.map-control div {
  /* box-shadow: inset 12px 0px 12px -12px rgba(0, 0, 0, 0.45),
    inset -12px 0px 12px -12px rgba(0, 0, 0, 0.45); */
}

.map-control-list li {
  display: inline-block;
  padding: 0 0 0 5px;
  font-size: 15px;
  border-left: 1px solid rgb(73, 94, 109);
  cursor: pointer;
  margin: 0 4px 3px 0px;
}

.map-control-list li:first-child {
  border-left: none;
}

.selected {
  /* border: solid 2px #607880; */
  background-color: #607880;
  border-radius: 4px;
  color: #f2eeee;
}

.inner {
  padding: 3px 5px 5px;
}

.zmax {
  z-index: 12 !important;
}

.selectors {
  display: grid;
  grid-template-columns: 65% auto;
  grid-column-gap: 10px;
}

.maps-wrapper {
  height: calc(100vh - 190px); /*  height: calc(100vh - 223px);
*/
  margin-top: 15px;
  border: 1px solid #999;
  overflow: overlay;
}

.center {
  text-align: center;
}

.map-display {
  padding: 5px 20px 20px 20px;
  overflow-y: hidden;
  height: calc(100%);
}

.Collapsible{
  padding: 5px 20px 5px 20px;
  border-bottom: 1px solid rgb(177, 177, 177);
  background: #e1dfdf;
}
.Collapsible__contentInner{
  font-weight: normal;
}

/*.Collapsible__contentOuter{
}*/

.Collapsible__trigger{
  cursor: pointer;
  font-weight: bold;
}

.Collapsible__trigger::before{
  font-family: 'Font Awesome 5 Free' ;
  content: '\f054' ;
  margin-right: 5px
}

.Collapsible__trigger.is-open::before{
  content: '\f078' ;
}

/* width */
.map-display::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.map-display::-webkit-scrollbar-track {
  background: rgb(247, 246, 246);
}

/* Handle */
.map-display::-webkit-scrollbar-thumb {
  background: #ababab;
}

/* Handle on hover */
.map-display::-webkit-scrollbar-thumb:hover {
  background: #bababa;
}

.graph-note {
  color: grey;
  font-size: smaller;
  margin-bottom: 5px;
  white-space: pre-wrap;
}

header {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  position: relative;
  border-bottom: 2px solid #00a5dc;
}

/* NAVIGATION */
.nav {
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  align-items: center;
  grid-gap: 15px;
  padding: 2px 10px;
  background-color: #fff; /*#e2e4e6;*/
}
.nav a {
  display: flex;
}

.nav__link img {
  max-height: 40px;
}
.nav__link--title {
  margin-left: auto;
  margin-right: auto;
  color: #3c3c3c;
}

.nav__link--title h1 {
  margin: 0;
}

.nav__link--title span {
  color: #009ddb;
}

