.leverSelected {
  background: #999898;
  color: #f2f2f2;
  font-weight: bold;
}

.lever {
  padding: 3px 2px 3px 2px;
}

.all-selectors {
  min-width: 120px;
}

.pl-2 {
  padding-left: 1rem;
}

.all-selectors > * {
  display: inline-block;
}

.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}

.hide {
  display: none;
}

.mr {
  margin-right: 5px;
}

.headline {
  background-color: #e1dfdf;
  border-bottom: 1px solid rgb(128, 127, 127);
  font-weight: bold;
  font-size: 14px;
}

.group_1 {
  background-color: rgb(247, 246, 246);
  border-bottom: 1px solid rgb(128, 127, 127);
  font-weight: bold;
  font-size: 13px;
}

.group_2 {
  font-weight: bold;
  border-bottom: 1px solid rgb(179, 178, 178);
  padding-left: 10px;
}

.title:last-child {
  border-bottom: 1px solid rgb(128, 127, 127);
}
